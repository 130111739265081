<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Settings
			.widget-body
</template>
<script>
export default {
	name: 'Settings',
	data(){
		return {
			busy: false,
		}
	},
	methods: {
		// async loadData(){
		// 	this.busy = true;
		// 	// let params = {
		// 	// 	page: this.paginate.page,
		// 	// 	page_size: this.paginate.limit,
		// 	// 	keyword: this.filters.keyword
		// 	// };
		// 	try {
		// 		let resp = await this.$ovReq.get('settings/getConfig');
		// 		console.log(resp);
		// 		// this.records = resp.records;
		// 		// this.paginate.total = resp.total;
		// 		// this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
		// 	} catch (e) {
		// 		console.error(e);
		// 	}
		// 	this.busy = false;
		// }
	},
	created(){
		// this.loadData();
	}
}
</script>
